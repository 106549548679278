const idPortalApi = "182bdef80532a47a74870ce5fe4461629b2857e8";

module.exports = {
  idPortalApi: `${idPortalApi}`,
  // Produção
  urlApi: `https://api-portais-workcenter.com.br/${idPortalApi}`,
  //urlApi: `http://ec2-54-232-59-57.sa-east-1.compute.amazonaws.com:8004/${idPortalApi}`,
  // Local
  // urlApi: `http://localhost:8004/${idPortalApi}`,

  //config botões e imagens
  urlADM: `https://administracaopublica.com.br`,

  dnsPortal: "miguelalves.pi.gov.br",
  NomePortal: "Miguel Alves",
  ufPortal: "PI",
  NomeTipoPortal: "Prefeitura Municipal", //Prefeitura ou Câmara
  TipoPortal: 3, //3 = Prefeitura 4=Câmara

  //Variável em teste (newsViewRoute)
  newsViewRoute: false, //Essa opção informa se a rota de visualização de uma única noticia está disponível (Raposa gera as noticias pelo lado do servidor com nextjs)

  countNewsHome: 12, //quantidade de noticias na home

  RoutesDaily: true, //Deixa ativado as rotas para a pagina de diario

  link: "http://www.transparenciadministrativa.com.br", //Onde o token abaixo ser[a utilizado]
  linkArquivos: "http://www.transparenciadministrativa.com.br/portalimagem", //Onde o token abaixo ser[a utilizado]
  tokenLink: `${idPortalApi}`, //Token para acessar o WWW.transparência

  urlEmDeploy: "https://miguelalves.pi.gov.br", //usada para link de compartilhamento
  imagemURL: "https://arquivos-api-portais.s3.sa-east-1.amazonaws.com/", //link para a imagem
  //imagemURL: 'http://localhost:8004/uploads/', //link para a imagem

  corIcones: "#000080",

  link_youtube: `#`,
  link_localize: `https://maps.app.goo.gl/yjkCrXRnb39QHAFZA`,
  //Mapa da pagina Municipio/Mapa
  link_mapa: `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12414.380860629106!2d-42.89514785!3d-4.174338!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x78d0b040cd45217%3A0x899d07ab71b5e8fd!2sMiguel%20Alves%2C%20PI%2C%2064130-000!5e1!3m2!1spt-BR!2sbr!4v1729777563395!5m2!1spt-BR!2sbr`,
};
